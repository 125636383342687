import { logger } from '~/src/common/services/Logger';
import envConstants from '~/src/env-constants';

import { IdentifyProperties, ampli } from './generated';
import { EventProperties, EventName } from './types';

const init = () => {
  if (!ampli.isLoaded) {
    ampli.load({
      client: {
        apiKey: envConstants.AMPLITUDE_API_KEY,
        configuration: {
          defaultTracking: {
            // On souhaite tracker les UTM dans les user properties, ils seront mis à jour à chaque nouvelle session
            attribution: true,
            pageViews: false,
            sessions: false,
            formInteractions: false,
            fileDownloads: false,
          },
        },
      },
    });
  }
};

const isInitialised = () => ampli.isLoaded;

const setUserId = (userId: string | undefined) => {
  init();

  ampli.client.setUserId(userId);

  if (typeof userId === 'string' && userId.length > 16) {
    logger.warning('Invalid Amplitude UserID', { invalidUserID: userId });
  }
};

const setUserProperties = (userProperties: IdentifyProperties) => {
  init();

  ampli.identify(undefined, userProperties);
};

const sendEvent = <T extends EventName>(eventName: T, eventProperties?: EventProperties<T>) => {
  init();

  ampli.track({ event_type: eventName, event_properties: eventProperties });
};

const sendViewEvent = <T extends EventName>(params: {
  eventName: T;
  eventProperties?: EventProperties<T>;
  userProperties?: IdentifyProperties;
}) => {
  init();

  const { userProperties, eventName, eventProperties } = params;

  if (userProperties != null) {
    setUserProperties(userProperties);
  }

  ampli.track({
    event_type: eventName,
    event_properties: eventProperties,
  });
};

const Tracker = { isInitialised, setUserId, setUserProperties, sendEvent, sendViewEvent };

export default Tracker;

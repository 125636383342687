import React, { useEffect, useRef } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import router, { useRouter } from 'next/router';

import DeliveryProgressBar from '~/src/common/components/DeliveryProgressBar';
import Navigation from '~/src/common/components/Navigation/Navigation/Navigation';
import { useDelivery } from '~/src/common/hooks/use-delivery';
import I18n from '~/src/common/services/I18n';
import { useNiceModal } from '~/src/common/services/ModalsManager';
import Toaster from '~/src/common/services/Toaster';
import Tracker from '~/src/common/services/Tracker';
import { getDeliveryDateWithTimeslotInterval } from '~/src/common/utils/date';
import { getDeliveryIconName } from '~/src/common/utils/delivery';
import { noop } from '~/src/common/utils/function';
import { isHomePathname } from '~/src/common/utils/home';
import { isOrderStateTerminated } from '~/src/common/utils/orders';
import { isServerSide } from '~/src/common/utils/server';
import { getCurrentOrdersQueryKey, useRecompute2 } from '~/src/queries/api-ecom/generated/api-ecom';
import { CurrentOrders200 } from '~/src/queries/api-ecom/generated/api-ecom.schemas';
import { User } from '~/src/queries/types/user';
import Footer from '~/src/screens/App/Footer';
import DeliveryProgressBanner from '~/src/screens/App/Header/DeliveryProgressBanner/DeliveryProgressBanner';
import { useTimerDisplayDeliveryBanner } from '~/src/screens/App/Header/DeliveryProgressBanner/hook';
import HeaderNavigation from '~/src/screens/App/Header/Navigation';
import { useAppInit } from '~/src/screens/App/hooks';
import InProgressOrderBanner from '~/src/screens/Home/InProgressOrdersBanner/InProgressOrderBanner';
import InProgressOrdersBanner from '~/src/screens/Home/InProgressOrdersBanner/InProgressOrdersBanner';

import { CatalogLayoutContainer } from './layout';
import { SMART_BANNER_ID, getDeliveryModalStep, isDelivery, isNextOrderDelivery } from './utils';

export interface CatalogLayoutProps {
  user?: User;
  numberOfCategories?: number;
}

type Props = {
  children: React.ReactElement<CatalogLayoutProps>;
  areProductsDisplayed?: boolean;
};

const CatalogLayout = ({ children, areProductsDisplayed = true }: Props) => {
  const { query, pathname } = useRouter<{
    slug: string;
    referral?: string;
    categorySlug: string;
  }>();
  const { rootCategories, user } = useAppInit();

  const hasSignupModalBeenOpenedWithReferral = useRef(false);
  const accountManagementModal = useNiceModal('account-management-modal');
  const { visible: isCartModalOpen } = useNiceModal('cart-modal');

  const { data: cart } = useRecompute2({ query: { enabled: false } });
  const { timeSlot } = useDelivery(cart);
  const { show: openDeliveryModal } = useNiceModal('delivery-modal');

  const totalAmount = cart?.price?.quotation.net || 0;

  const queryClient = useQueryClient();

  const isJaugeProgressionActive = useTimerDisplayDeliveryBanner();

  useEffect(() => {
    if (!isServerSide() && router.query.deliverySlotToast && timeSlot != null) {
      Tracker.sendEvent('shipping slot toaster viewed');
      const translationKey = isDelivery(cart?.delivery) ? 'delivery' : 'pickup';
      Toaster.notify({
        message: I18n.translate({
          value: `delivery-modal.${translationKey}-notification`,
          slot: getDeliveryDateWithTimeslotInterval(timeSlot),
        }),
        icon: getDeliveryIconName(cart?.delivery.deliveryZone?.pickupType),
        actionButtonLabel: I18n.t('common.modify'),
        onActionButtonClick: () => {
          Tracker.sendEvent('shipping slot toaster click');
          openDeliveryModal({ initialStep: getDeliveryModalStep(cart?.delivery) }).catch(noop);
        },
      });
      void router.replace(router.pathname, undefined, { shallow: true, scroll: false });
    }
  }, [cart, openDeliveryModal, pathname, timeSlot]);

  useEffect(() => {
    if (query.referral && !hasSignupModalBeenOpenedWithReferral.current) {
      hasSignupModalBeenOpenedWithReferral.current = true;

      // eslint-disable-next-line @typescript-eslint/no-floating-promises  -- auto-ignored when updating eslint
      accountManagementModal.show({ showSignup: true });
    }
  }, [query.referral, accountManagementModal]);

  const isHome = isHomePathname(pathname);
  const isCatalog = pathname.startsWith('/categorie/');

  const mainCategories = rootCategories.filter(c => !c.tags?.includes('Home'));

  const currentOrders = queryClient.getQueryData<CurrentOrders200>(getCurrentOrdersQueryKey());

  const inProgressOrders = currentOrders?.items.filter(o => !isOrderStateTerminated(o.state)) || [];
  const orderDelay = inProgressOrders?.[0]?.delay?.duration;
  const isProgressOrdersBannerDisplayed = isHome && inProgressOrders.length > 0;

  const deliveryPricesWithDelta = cart?.delivery?.timeSlot?.deliveryPricesWithDeltas || [];

  const shippingAmounts = deliveryPricesWithDelta.map(dp => dp.shippingAmount);
  const maxShippingAmount = Math.max(...shippingAmounts);
  const isJaugeProgressionDisplayed =
    (isHome || isCatalog) && !isCartModalOpen && isJaugeProgressionActive && maxShippingAmount > 0;

  return (
    <>
      <div id={SMART_BANNER_ID} />
      <HeaderNavigation />

      <DeliveryProgressBanner isDisplayed={isJaugeProgressionDisplayed}>
        <DeliveryProgressBar
          totalCartAmount={totalAmount}
          deliveryPrices={deliveryPricesWithDelta}
        />
      </DeliveryProgressBanner>

      {isProgressOrdersBannerDisplayed ? (
        inProgressOrders.length === 1 ? (
          <InProgressOrderBanner
            state={inProgressOrders[0].state}
            delivery={inProgressOrders[0].delivery}
            orderId={inProgressOrders[0].id}
            delay={orderDelay}
            isJaugeProgressionDisplayed={isJaugeProgressionDisplayed}
          />
        ) : (
          <InProgressOrdersBanner
            isNextOrderDelivery={isNextOrderDelivery(inProgressOrders)}
            count={inProgressOrders.length}
            isJaugeProgressionDisplayed={isJaugeProgressionDisplayed}
          />
        )
      ) : null}

      <Navigation showTitle hideOnMobile={!isHome} source="Homepage" />

      {areProductsDisplayed ? (
        <CatalogLayoutContainer $isProgressOrdersBannerDisplayed={isProgressOrdersBannerDisplayed}>
          {React.cloneElement(children, { user, numberOfCategories: mainCategories.length })}
        </CatalogLayoutContainer>
      ) : (
        <div>{React.cloneElement(children, { user })}</div>
      )}

      <Footer hasOrderStatus={isProgressOrdersBannerDisplayed} />
    </>
  );
};

export default CatalogLayout;

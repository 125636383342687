import styled from 'styled-components';

import type colors from '~/src/common/themes/main/colors';

import { IconNames } from './Icon.d';

interface Props {
  size?: number;
  color?: keyof typeof colors;
  disabled?: boolean;
  name: IconNames;
}

const Icon = styled.span.attrs<Props>(props => ({
  className: `icon-${props.name}`,
  role: 'img',
}))<Props>`
  color: ${({ color, theme }) => (color != null ? theme.palette.common[color] : 'inherit')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  font-size: ${({ size }) => (size != null ? `${size}px` : 'inherit')};
`;

export default Icon;

import { useEffect, useRef } from 'react';

import { PAGE_VIEWS, TagManager } from '~/src/common/services/TagManager';
import Tracker from '~/src/common/services/Tracker';

import { ErrorHeader } from '../layout';
import { DialogVariantProps } from '../types';

const ErrorDialog = ({ title, description }: DialogVariantProps) => {
  // L'utilisation de refs est indispensable ici car le titre et la description
  // peuvent être des éléments React. Il faut attendre que ces nodes soient
  // montées dans le DOM avant de pouvoir extraire le contenu textuel.
  const titleRef = useRef<HTMLElement>(null);
  const descrRef = useRef<HTMLElement>(null);
  useEffect(() => {
    Tracker.sendEvent('error modal view', {
      'dialog type': 'error',
      'dialog title': titleRef.current?.textContent ?? '',
      'dialog message': descrRef.current?.textContent ?? '',
      'page path': window.location.pathname,
    });

    TagManager.setDataLayer({
      event: PAGE_VIEWS.event,
      PageType: PAGE_VIEWS.pageType.errorDialog,
    });
  }, []);

  return (
    <>
      {title != null ? (
        <ErrorHeader>
          <span ref={titleRef}>{title}</span>
        </ErrorHeader>
      ) : null}
      <span ref={descrRef}>{description}</span>
    </>
  );
};

export default ErrorDialog;

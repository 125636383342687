import Icon from '~/src/common/components/Icon';
import { useScreenSize } from '~/src/common/hooks/use-screen-size';
import I18n from '~/src/common/services/I18n';

import useNavigationActions from './hooks/use-navigation-actions';
import * as S from './layout';

interface Props {
  onSearchClick: () => void;
}

const HeaderButtons = ({ onSearchClick }: Props) => {
  const { isLargeScreen } = useScreenSize();
  const {
    hasOngoingOrders,
    cartProductsQuantity,
    handleRecipesClick,
    handleListsClick,
    handleAccountClick,
    handleCartClick,
  } = useNavigationActions();

  const cartButton = (
    <S.TransparentButton
      aria-label={I18n.t('navigation.menu.cart')}
      onClick={handleCartClick}
      title={I18n.t('navigation.menu.cart')}>
      <S.CartIconContainer>
        <Icon name="basket-normal" color="BACKGROUND_1" size={24} />
        <S.SizeBullet>{cartProductsQuantity}</S.SizeBullet>
      </S.CartIconContainer>
      {isLargeScreen ? <span>{I18n.t('navigation.menu.cart')}</span> : null}
    </S.TransparentButton>
  );

  if (!isLargeScreen) {
    return (
      <>
        <S.TransparentButton
          onClick={onSearchClick}
          title={I18n.t('navigation.menu.search')}
          aria-label={I18n.t('navigation.menu.search')}>
          <Icon name="search-normal" color="WHITE" size={24} />
        </S.TransparentButton>
        {cartButton}
      </>
    );
  }

  return (
    <>
      <S.TransparentButton
        onClick={handleRecipesClick}
        title={I18n.t('navigation.menu.recipes')}
        aria-label={I18n.t('navigation.menu.recipes')}>
        <Icon name="cooking-hat" color="WHITE" size={24} />
        <span>{I18n.t('navigation.menu.recipes')}</span>
      </S.TransparentButton>
      <S.TransparentButton
        onClick={handleListsClick}
        title={I18n.t('navigation.menu.my-products')}
        aria-label={I18n.t('navigation.menu.my-products')}>
        <Icon name="heart-normal" color="WHITE" size={24} />
        <span>{I18n.t('navigation.menu.my-products')}</span>
      </S.TransparentButton>
      <S.TransparentButton
        onClick={handleAccountClick}
        title={I18n.t('navigation.menu.profile')}
        aria-label={I18n.t('navigation.menu.profile')}
        $hasNotification={hasOngoingOrders}>
        <Icon name="profile-normal" color="WHITE" size={24} />
        <span>{I18n.t('navigation.menu.profile')}</span>
      </S.TransparentButton>
      {cartButton}
    </>
  );
};

export default HeaderButtons;

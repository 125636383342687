import I18n from '~/src/common/services/I18n';
import { noop } from '~/src/common/utils/function';
import { isApiEcomError } from '~/src/common/utils/guards';
import { pick } from '~/src/common/utils/object';
import { appendQueryParams } from '~/src/common/utils/url';

import { Params } from '../types';

const NETWORK_ERROR_MESSAGE = I18n.t('errors.network.description');

export const fetchOnBrowser = async <T>(input: Params): Promise<T> => {
  const { url, data, params, forwardCookies, ...init } = input;
  const fullUrl = appendQueryParams(url, params);

  // https://github.com/github/fetch/issues/254
  const method = init.method.toUpperCase();
  const response = await fetch<T>(fullUrl, {
    ...{ ...init, method },
    ...(method !== 'GET' && { body: JSON.stringify(data ?? {}) }),
    headers: { 'Content-Type': 'application/json', ...init.headers },
    credentials: forwardCookies ? 'include' : 'omit',
  })
    // En cas d'erreur de fetch, on throw une erreur générique
    .catch(err => {
      const error = new Error(NETWORK_ERROR_MESSAGE);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment  -- auto-ignored when updating eslint
      throw Object.assign(error, { extra: { originalError: err } });
    });

  // Dans le cas où on reçoit un code non 2xx, on tente de parser le retour de la requête
  // On relance l'erreur si elle vient de l'api ecom, sinon on throw une erreur générique
  if (!response.ok) {
    // Création de l'erreur et surcharge api ecom
    const error = new Error(NETWORK_ERROR_MESSAGE);
    const infos = await response.json().catch(noop);
    if (isApiEcomError(infos)) Object.assign(error, infos);
    // Ajout des données complémentaires et throw
    const res = pick(response, ['status', 'statusText', 'url']);
    throw Object.assign(error, { extra: { res, infos } });
  }

  // Pour les 204, on ne parse pas le retour car il est vide
  return response.status === 204 ? <T>{} : response.json();
};

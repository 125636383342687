export default {
  'title': 'Choisissez votre créneau',
  'banner': {
    title: 'Le jour de livraison n’est pas modifiable',
    subTitle: 'La modification n’est possible que pour un créneau du même jour de livraison.',
    reason:
      "Afin de vous garantir que l'ensemble de vos produits soient disponibles et au top de leur fraicheur, votre jour de livraison n'est pas modifiable. C'est aussi le moyen pour nous de minimiser le gaspillage alimentaire.",
  },
  'no-slots': "Aucun autre créneau n'est disponible pour votre jour de livraison",
};

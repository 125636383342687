import { useCartWithTimeslot } from '~/src/common/hooks/use-cart-with-timeslot';
import { useDelivery } from '~/src/common/hooks/use-delivery';
import { useNiceModal } from '~/src/common/services/ModalsManager';
import Tracker from '~/src/common/services/Tracker';
import { noop } from '~/src/common/utils/function';
import { isPickupDelivery } from '~/src/screens/Account/OrdersScreen/utils';
import { getDeliveryModalStep } from '~/src/screens/App/CatalogLayout/utils';

const useDeliveryActions = () => {
  const cart = useCartWithTimeslot();
  const delivery = useDelivery(cart);
  const deliveryModal = useNiceModal('delivery-modal');

  const handleDeliverySlotClick = () => {
    deliveryModal
      .show(deliveryModal.visible ? undefined : { initialStep: getDeliveryModalStep(delivery) })
      .catch(noop);

    Tracker.sendEvent('user navigation menu', { 'navigation text': 'shipping slot' });
  };

  const handleDeliveryAddressClick = () => {
    deliveryModal.show(undefined).catch(noop);

    Tracker.sendEvent('user navigation menu', { 'navigation text': 'shipping address' });
  };

  return {
    deliveryZoneName: delivery.deliveryZone?.name ?? delivery.shop?.name,
    deliveryAddress:
      delivery.address?.addressComponents ?? cart?.delivery?.address.addressComponents,
    timeSlot: delivery.timeSlot,
    isPickupMode: isPickupDelivery(delivery),
    isDeliveryModalOpen: deliveryModal.visible,
    closeDeliveryModal: deliveryModal.remove,
    handleDeliverySlotClick,
    handleDeliveryAddressClick,
  };
};

export default useDeliveryActions;

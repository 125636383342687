import { AnchorHTMLAttributes, PropsWithChildren } from 'react';

// eslint-disable-next-line no-restricted-imports
import NextLink, { LinkProps } from 'next/link';

const Link = (
  props: PropsWithChildren<
    Omit<AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> & LinkProps
  >,
) => {
  return <NextLink prefetch={false} {...props} />;
};

export default Link;

export default {
  'my-account': 'Informations personnelles',
  'my-delivery-addresses': 'Adresse de livraison',
  'my-payment-options': 'Moyens de paiement',
  'my-orders': 'Commandes',
  'my-account-subscriptions': 'Préférences',
  'my-referral': 'Parrainage',
  'logout-button': 'Me déconnecter',
  'referral': '<span>Parrainez un ami et recevez</span><span>2x15€</span>',
};

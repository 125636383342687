import { Order } from '~/src/common/typings/order';

export const isOrderStateDelivering = (orderState: Order['state']) => {
  const deliveringStatus = ['approvedByClient', 'inTransit', 'prepared', 'readyToDeliver'];

  return deliveringStatus.includes(orderState);
};

export const isOrderStateTerminated = (orderState: Order['state']) => {
  const terminatedStatus = ['paid', 'delivered', 'expired', 'returned', 'returning', 'refunded'];

  return terminatedStatus.includes(orderState);
};

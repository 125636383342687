export default {
  'pickup-mode-collect': 'Piéton',
  'pickup-mode-drive': 'Drive',
  'close-label': {
    'delivery': "Fermer la sélection d'adresse de livraison",
    'pickup': 'Fermer la sélection du point de retrait',
    'select-slot': 'Fermer la sélection du créneau',
  },
  'steps': {
    'add-address': {
      'no-result-matching': 'Vous ne trouvez pas votre adresse ?',
      'use-manual-input': 'Utiliser la saisie manuelle',
      'have-an-account': 'Vous avez déjà un compte ?',
      'login-to-fetch-addresses1': 'Connectez-vous ',
      'login-to-fetch-addresses2': ' pour récupérer vos adresses',
      'catch-phrase': 'Faites-vous livrer à domicile !',
      'title': 'Saisissez votre adresse',
      'title2': 'On vous livre où ?',
      'not-delivered1':
        'Nous ne livrons pas encore cette adresse mais vous pouvez passer commande en choisissant',
      'not-delivered2': ' un point de retrait.',
      'here': 'ici',
      'here-aria-label': "sélectionner l'adresse %{name}",
    },
    'manual-address': {
      'catch-phrase': 'Faites-vous livrer à domicile !',
      'manual': 'Saisie manuelle',
      'precise-address': "Merci d'indiquer votre adresse",
      'confirm': 'Valider ma saisie manuelle',
      'postal-code-placeholder': '75001 Paris',
      'postal-code-label': 'Code postal',
      'street-number-placeholder': '20',
      'street-number-label': 'N°',
      'street-placeholder': 'Rue de Harlay',
      'street-label': 'Libellé de la voie',
      'map-instructions':
        "Puis déplacer la carte pour placer le curseur sur votre adresse et confirmer l'emplacement",
      'map-overlay-text': 'Déplacer la carte pour placer le curseur où le livreur doit se rendre !',
    },
    'manual-map': {
      'title': 'Et pour être sûrs de vous trouver, placez le curseur sur votre adresse',
      'title-mobile': 'Placez le curseur sur votre adresse',
      'map-value-error': 'Les coordonnées de votre position sont erronnées',
      'confirm': 'Confirmer',
    },
    'manual-postal-code': {
      'title': 'Essayons autrement, commençons par votre code postal',
      'title-mobile': 'Votre code postal',
    },
    'manual-street': {
      'title-mobile': 'Votre adresse',
      'title': 'Maintenant votre adresse',
    },
    'select-slot': {
      'no-timeslot-available': "Aucun créneau n'est disponible actuellement",
      'title': 'Choisissez votre créneau',
      'closing-event': {
        'title': 'Fermeture exceptionnelle ',
        'date': 'du %{startDate} au %{endDate} inclus',
        'date-one': 'le %{date}',
      },
    },
    'select-zone': {
      'choose-zone': 'Choisissez un point de retrait',
      'distance-from': '%{distance} km',
      'eligible': "Profitez de l'expérience Clic & Cueillette !",
      'zones': 'Nos points de retrait',
      'zone-closest': 'Le plus proche de votre position',
      'zone-rest': 'Les autres points de retrait',
      'not-available': "Il n'y a aucun point de retrait dans cette zone :(",
      'not-available-button': 'Afficher les autres points de retrait',
      'opening-hours': 'Retrait de 8h à 21h - 7j/7',
      'show-directions': "Voir l'itinéraire",
      'input-search-placeholder': 'Rechercher des points de retrait à proximité',
      'delivery': {
        'marker-title': 'Votre adresse',
        'marker-button': 'Livrer à domicile',
        'marker-text': 'Domicile',
      },
      'pickup': {
        'marker-button': 'Choisir ce point de retrait',
        'distance-from-address': '%{distance} km de votre adresse',
      },
    },
    'select-mode': {
      'how-to-mode': 'Comment souhaitez vous venir récupérer votre commande ?',
      'how-to-subtitle': 'Les instructions de récupération changent selon votre mode de retrait',
      'unavailable': "Aucun créneau n'est disponible pour ce point de retrait. :(",
      'choose-button': 'Choisir',
    },
    'back': 'Retour',
  },
  'tabs': {
    delivery: 'LIVRAISON',
    pickup: 'RETRAIT',
  },
  'delivery-notification': 'Livraison le <span>%{slot}</span>',
  'pickup-notification': 'Retrait le <span>%{slot}</span>',
  'prices-update-notification': 'Les prix et disponibilités des produits ont été mis à jour',
  'toaster-auto-select-timeslot': {
    success: 'Créneau expiré. Nouveau créneau sélectionné : <span>%{slot}</span>',
    fail: 'Créneau expiré. Aucun prochain créneau disponible.',
  },
};

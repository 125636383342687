import I18n from '~/src/common/services/I18n';
import { useNavigation } from '~/src/queries/api-ecom/generated/api-ecom';

import FamilyList from '../FamilyList/FamilyList';
import { NavigationSource, NavigationTrackingProvider } from '../tracking';

import * as S from './layout';

type Props = {
  source: NavigationSource;
  showTitle?: boolean;
  hideOnMobile?: boolean;
};

const Navigation = ({ source, showTitle, hideOnMobile }: Props) => {
  const { data } = useNavigation();

  return (
    <NavigationTrackingProvider navigation={data} source={source}>
      <S.Container $hideOnMobile={hideOnMobile}>
        {showTitle && <S.Title>{I18n.t('common.our-stalls')}</S.Title>}
        {data == null ? <S.Placeholder /> : <FamilyList families={data.families} />}
      </S.Container>
    </NavigationTrackingProvider>
  );
};

export default Navigation;

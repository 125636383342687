export default {
  'my-referral-code': 'Mon code parrain',
  'add-my-code': "J'ajoute mon code",
  'copy-my-code': 'Je copie mon code',
  'share-my-code': 'Je partage mon code',
  'your-referral-code-is': 'Votre code parrain est :',
  'referee-title': 'Faites-vous parrainer !',
  'referee-description-is-referred':
    'Vous êtes bien parrainés, retrouvez vos récompenses quand vous passez commande. Attendez de recevoir votre première commande pour parrainer vos amis, votre famille ou vos voisins :)',
  'referee-description':
    'Demandez à un proche de vous parrainer et gagnez des récompenses !<br/>Ajoutez un code parrain avant de passer votre première commande.',
  'share-code-title': 'Un bon plan, ça se partage !',
  'receive-your-gift': 'Recevez votre première commande pour devenir parrain.',
  'share-code-description':
    'Vous avez aimé nos délicieux produits frais ? Pourquoi ne pas en parler autour de vous ?<br/>Vous aussi, devenez ambassadeur du goût, parrainez vos proches et gagnez des récompenses !',
  'mailto': {
    subject: "Jour de chance, je t'offre un panier de bons fruits et légumes mon-marché 🤩",
  },
  'too-early': {
    'to-be-referral': 'Il est trop tôt pour être parrain !',
    'description':
      'Attendez de recevoir votre première commande pour parrainer vos amis, votre famille ou vos voisins :)',
  },
  'referrer-gift': {
    title: 'Cadeau pour vous, le parrain',
    description: '2x15€ offerts',
    condition: 'Pour vos prochaines commandes, montant minimim de 30€',
  },
  'referee-gift': {
    title: 'Cadeau pour votre filleul',
    description: 'Un panier découverte offert',
    condition: 'Pour sa prochaine commande',
  },
};

export default {
  'my-saved-delivery-addresses': 'Mes adresses enregistrées',
  'add-a-delivery-address': 'Ajouter une adresse de livraison',
  'delete-this-delivery-address': 'supprimer cette adresse',
  'delete-this-delivery-address-label': "supprimer l'adresse %{name}",
  'modify-this-delivery-address-label': "modifier l'adresse %{name}",
  'delete-modal': {
    'question-delete-address': 'Êtes-vous sûr de vouloir supprimer cette adresse ?',
    'no': 'Non, annuler',
    'yes': 'Oui, supprimer cette adresse',
  },
};

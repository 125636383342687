export default {
  'secure-description':
    "<p>Toutes vos informations bancaires sont cryptées et sauvegardées sur des services bancaires spécialisés.</p><p>Nous n'enregistrons pas vos coordonnées sur nos serveurs.</p>",
  'secure-message': 'Protocoles de transactions bancaires cryptées et sécurisées',
  'add-card': 'Ajouter une carte',
  'delete-card': 'Supprimer cette carte',
  'is-expired': 'Carte expirée',
  'delete-confirmation': {
    'message': 'Voulez-vous vraiment supprimer cette carte ?',
    'title': 'Suppression de votre carte',
    'confirmation-message': 'Votre carte a bien été supprimée',
  },
  'add-form': {
    'card-number': 'Numéro de carte',
    'card-expiration-date': "Date d'expiration",
    'card-cvc': 'CVC code',
    'submit': 'Ajouter',
    'unknown-error': "Une erreur est survenue lors de l'ajout de carte",
    'save-card': 'Enregistrer ma carte',
  },
};

export default {
  status: {
    'pickup-ready': 'Commande prête pour récupération',
    'accepted-by-client': 'Acceptée',
    'preparing': 'En préparation',
    'delivering': 'En livraison',
    'cancelled': 'Annulée',
    'terminated': 'Terminée',
    'refunded': 'Remboursée',
    'ready': 'Commande disponible',
  },
};

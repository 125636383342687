import { NextRouter } from 'next/router';

import { BasicProduct } from '~/src/common/components/GridCard/ProductCard/type';
import dayjs from '~/src/common/services/Date';
import { IDS } from '~/src/common/services/TagManager';
import { Cart, DeliverySlot, DeliveryZone } from '~/src/common/typings/cart';
import { Order } from '~/src/common/typings/order';
import { ItemDefinition } from '~/src/common/typings/product';
import { getPromoPercentage } from '~/src/common/utils/prices';
import {
  isFreeDeliveryBannerDisplayed,
  getCommonSlotEventProperties,
} from '~/src/common/utils/slots';

import Tracker from './Tracker';
import {
  AddToCartProductEventSource,
  AddToCartStatus,
  BookmarkListProperties,
  CartEventProperties,
  DeliveryOrderStatus,
  EventName,
  EventProperties,
  ExtraCartEventProperties,
  PickupType,
  ZoneProperties,
} from './types';

const UNTRACKED_PAGE = [
  '404',
  // les pages catégories et landing sont trackées directement depuis leur composant screen
  'produit',
  'categorie',
  'landing',
  'covid19',
  'checkout',
  'recette',
];

export const getEventBrowseValues: (
  url: string,
  query: NextRouter['query'],
) => {
  eventName: EventName;
  eventProperties: EventProperties<EventName>;
} | null = (url, query) => {
  if (query.q != null) return null;

  if (url === '/' || url === '/reset-password') {
    return {
      eventName: 'homepage viewed',
      eventProperties: {
        'current page path': '/',
        'current page name': 'homepage',
        'current page type viewed': 'homepage',
      },
    };
  }

  if (url.startsWith('/account/')) {
    const eventName = 'user portal viewed';

    return {
      eventName,
      eventProperties: {
        'current page path': url,
        'current page name': url.split('/').pop(),
        'current page type viewed': 'user portal',
      },
    };
  }

  const splitUrl = url.split('/');

  if (!UNTRACKED_PAGE.some(untrackedPage => untrackedPage === splitUrl[1])) {
    const eventName = 'brand page viewed';

    const hasDeepRouting = splitUrl.length > 1;

    return {
      eventName,
      eventProperties: {
        'current page path': url,
        'current page name': hasDeepRouting ? splitUrl.pop() : url.substring(1),
        'current page type viewed': 'brand page',
      },
    };
  }

  // Les pages ne sont pas trackées par défaut
  return null;
};

type getBookmarkEventMappingPayload = {
  product: Pick<BasicProduct, 'name' | 'pimCategoryName' | 'canonicalId' | 'rating' | 'sku'> & {
    itemPrice?: number;
  };
  categoryName?: string;
  subcategoryName?: string;
  productEventSource: AddToCartProductEventSource;
};

export const getBookmarkEventMapping = ({
  product,
  categoryName,
  subcategoryName,
  productEventSource,
}: getBookmarkEventMappingPayload): BookmarkListProperties => ({
  'product name': product?.name,
  'product id': product?.canonicalId,
  'product category': categoryName || '',
  'product subcategory': subcategoryName || '',
  'product pim': product?.pimCategoryName || '',
  'product price': (product?.itemPrice ?? 0) / 100,
  'product event source': productEventSource,
  'product rating': product?.rating?.average,
  'nb of ratings': product?.rating?.nbRatings,
  'SKU': product?.sku,
});

export const DELIVERY_ORDER_STATUS: Partial<Record<Order['state'], DeliveryOrderStatus>> = {
  prepaying: 'PRÉ-PAYÉE',
  prepaid: 'PRÉ-PAYÉE',
  preparing: 'EN PRÉPARATION',
  prepared: 'PRÉPARÉE',
  approvedByClient: 'PRÉPARÉE',
  ready: 'DISPONIBLE',
  cart: 'EN LIVRAISON',
  readyToDeliver: 'EN LIVRAISON',
  inTransit: 'EN LIVRAISON',
  delivered: 'LIVRÉE',
  paid: 'PAYÉE',
};

export const PickupTypeValues: Record<string, PickupType> = {
  remotePickup: 'remote',
  delivery: 'shop',
  pickup: 'shop',
  onSitePickup: 'on site',
};

/** Mise à jour de l'utilisateur lors du changement de Delivery Zone */
export const getZoneProperties = (zone: DeliveryZone): ZoneProperties => ({
  'shop': zone.shop.name,
  'shipping type': zone.type === 'delivery' ? 'delivery' : 'pickup',
  'pickup mode': zone.type === 'delivery' || !zone.pickupType ? '-' : zone.pickupType,
  'pickup type': PickupTypeValues[zone.type],
  'delivery zone/pick-up point': zone.name,
});

const computeDeltaValues = (
  zone: DeliveryZone,
  validationMode?: 'auto' | 'manual',
  daySlots?: DeliverySlot[],
) => {
  const minDeltaDay =
    validationMode === 'manual'
      ? Math.min(...zone.deliverySlots.map(ds => ds.rate.deltaDay)) / 100
      : undefined;
  const maxDeltaDay =
    validationMode === 'manual'
      ? Math.max(...zone.deliverySlots.map(ds => ds.rate.deltaDay)) / 100
      : undefined;

  const minDeltaSlot =
    daySlots && daySlots.length
      ? Math.min(...daySlots.map(ds => ds.rate.deltaSlot)) / 100
      : undefined;
  const maxDeltaSlot =
    daySlots && daySlots.length
      ? Math.max(...daySlots.map(ds => ds.rate.deltaSlot)) / 100
      : undefined;

  return {
    minDeltaDay,
    maxDeltaDay,
    minDeltaSlot,
    maxDeltaSlot,
  };
};

/**
 * Wrapper pour l'évènement 'shipping slot validated' envoyant les
 * bonnes propriétés en fonction de la zone et du slot
 * @param slot
 * @param zone
 * @param validationMode surcharge de la propriété 'validation mode', par défaut à 'auto'
 * @param deltaShippingSlotStartTime surcharge de la propriété 'delta shipping slot start time' non définie par défaut
 * @param daySlots slots du jour uniquement, pour plus de précision dans les données trackées
 */
export const sendShippingSlotValidatedEvent = ({
  slot,
  zone,
  validationMode = 'auto',
  deltaShippingSlotStartTime = undefined,
  daySlots = undefined,
}: {
  slot: DeliverySlot;
  zone: DeliveryZone;
  validationMode?: 'auto' | 'manual';
  deltaShippingSlotStartTime?: number | string;
  daySlots?: DeliverySlot[];
}) => {
  const deliveryAd = isFreeDeliveryBannerDisplayed(daySlots ?? zone.deliverySlots) ? 'yes' : 'no';

  const zoneProperties = getZoneProperties(zone);

  const { minDeltaDay, maxDeltaDay, minDeltaSlot, maxDeltaSlot } = computeDeltaValues(
    zone,
    validationMode,
    daySlots,
  );

  const slotsPrices = (daySlots || [])
    ?.filter(s => !s.isFull && !s.isExpired)
    ?.map(s => s.deliveryPricesWithDeltas?.[0].shippingAmount ?? 0);

  const minRateViewed =
    slotsPrices && slotsPrices.length ? Math.min(...slotsPrices) / 100 : undefined;
  const maxRateViewed =
    slotsPrices && slotsPrices.length ? Math.max(...slotsPrices) / 100 : undefined;

  Tracker.setUserProperties(zoneProperties);

  Tracker.sendEvent('shipping slot validated', {
    'validation mode': validationMode,
    'delta shipping slot start time': deltaShippingSlotStartTime,
    'shipping cost delta day': (slot?.rate?.deltaDay || 0) / 100,
    'shipping cost delta slot': (slot?.rate?.deltaSlot || 0) / 100,
    'shipping cost viewed': (slot?.rate?.total || 0) / 100,
    'shipping cost delta day min': minDeltaDay,
    'shipping cost delta day max': maxDeltaDay,
    'shipping cost delta slot min': minDeltaSlot,
    'shipping cost delta slot max': maxDeltaSlot,
    'shipping cost viewed min': minRateViewed,
    'shipping cost viewed max': maxRateViewed,
    'free delivery ad': validationMode === 'manual' ? deliveryAd : undefined,
    ...getCommonSlotEventProperties(daySlots ?? zone.deliverySlots, slot),
    ...zoneProperties,
  });
};

/** Mise à jour de l'utilisateur lors du changement de Delivery Slot */
export const setTimeSlotUserProperties = (timeSlot: DeliverySlot) => {
  const today = dayjs().set('hour', 0).set('minutes', 0);
  const fromDayJs = dayjs(timeSlot.from);
  const startTime = fromDayJs.format('HH[h]mm');
  const endTime = dayjs(timeSlot.to).format('HH[h]mm');

  Tracker.setUserProperties({
    'shipping slot': `${startTime}-${endTime}`,
    'shipping slot start time': startTime,
    'shipping slot end time': endTime,
    'shipping slot days': fromDayJs.diff(today, 'day'),
  });
};

export const getCartEventName = (
  previousQuantity: number,
  newQuantity: number,
): {
  eventName: 'click add to cart' | 'click remove product';
  status: AddToCartStatus;
} => {
  if (newQuantity > previousQuantity) {
    return { eventName: 'click add to cart', status: 'product added' };
  }

  if (newQuantity < previousQuantity) {
    return { eventName: 'click remove product', status: 'product removed' };
  }

  return { eventName: 'click add to cart', status: 'product added' };
};

type getCartEventMappingPayload = {
  product: Pick<
    BasicProduct,
    | 'name'
    | 'pimCategoryName'
    | 'canonicalId'
    | 'promo'
    | 'rating'
    | 'packSize'
    | 'consumptionDate'
    | 'sku'
  > & {
    itemPrice?: number;
    itemDefinition?: ItemDefinition;
  };
  quantity: number;
  categoryName?: string;
  subcategoryName?: string;
  cart?: Cart;
  status: AddToCartStatus;
  productEventSource: AddToCartProductEventSource;
  otherAnalyticsProperties?: ExtraCartEventProperties;
};

export const getCartEventMapping = ({
  product,
  quantity,
  cart,
  status,
  productEventSource,
  categoryName,
  subcategoryName,
  otherAnalyticsProperties = {},
}: getCartEventMappingPayload): CartEventProperties => ({
  'product name': product.name,
  'product id': product.canonicalId,
  'product category': categoryName || '',
  'product subcategory': subcategoryName || '',
  'product pim': product.pimCategoryName,
  'product price': (product.itemPrice ?? 0) / 100,
  'product quantity': quantity,
  'product consumption date': product.consumptionDate
    ? dayjs(product.consumptionDate).format('DD/MM/YY')
    : undefined,
  'product rating': product.rating?.average,
  'nb of ratings': product.rating?.nbRatings,
  'order prepaid': (cart?.price?.quotation?.preauthorization ?? 0) / 100, // Total valeur panier
  'product event source': productEventSource, // Element source
  'add or remove status': status,
  'cart id': cart?.id ?? undefined,
  'shop': cart?.delivery?.shop?.name,
  'promo percent':
    product.promo != null ? getPromoPercentage(product.promo, product.itemPrice) : undefined,
  'promo mechanism': product.promo?.mechanism,
  'promo triggering quantity': product.promo?.conditions?.nthQuantity,
  'mdv3 granularity amount':
    product?.packSize && product.packSize > 1 ? product.packSize : undefined,
  'SKU': product?.sku,
  ...otherAnalyticsProperties,
});

export const getEventSourceFromList: (fromList?: string) => string = fromList => {
  switch (fromList) {
    case IDS.checkout:
      return 'checkout main product';

    case IDS.basket:
      return 'products list';

    case IDS.searchResults:
      return 'search results';

    case IDS.xSellCheckout:
      return 'checkout cross sell';

    case IDS.xSellMain:
      return 'main product cross sell';

    case IDS.headerMarketing:
      return 'header marketing';

    default:
      return 'products list';
  }
};

export default {
  'home': 'Accueil',
  'search': 'Rechercher',
  'promotions': 'promotions',
  'back': 'Retour',
  'validate': 'Valider',
  'lastName': 'Nom',
  'firstName': 'Prénom',
  'delete': 'Supprimer',
  'edit': 'Éditer',
  'email': 'Email',
  'cancel': 'Annuler',
  'submit': 'Soumettre',
  'loading': 'Chargement',
  'logout': 'Me déconnecter',
  'modify': 'Modifier',
  'change': 'Changer',
  'hours': 'Heures',
  'minutes': 'Minutes',
  'secondes': 'Secondes',
  'see-all': 'Voir tout',
  'see-more': 'Voir plus',
  'see-less': 'Voir moins',
  'see-more-aria': 'Voir plus',
  'see-less-aria': 'Voir moins',
  'postal-code': 'Code postal',
  'refresh-button-list': 'Rafraîchir la liste',
  'close': 'Fermer',
  'confirm': 'Valider',
  'signin': 'Me connecter',
  'signup': "M'inscrire",
  'ok': 'ok',
  'know-more': 'En savoir plus',
  'and': 'et',
  'c-n-c': 'C&C',
  'delivery': 'Votre livraison',
  'pickup': 'Votre retrait',
  'drive': 'Clic & Cueillette Drive',
  'car': 'Clic & Cueillette Voiture',
  'walk': 'Clic & Cueillette Piéton',
  'click-and-collect': 'Clic & Cueillette',
  'freshness-promise': 'Livré chez vous. 24H dans nos frigos/Fraîcheur maximum',
  'meta-description':
    'Poissons, viandes, charcuteries, fromages, fruits et légumes, le meilleur des produits frais livrés chez vous.',
  'unknown': 'Inconnu',
  'copied': 'copié',
  'who-we-are': 'Qui sommes-nous',
  'homepage': 'Livraison de courses à domicile en ligne - 7J/7 - mon-marché.fr',
  'all': 'Tous',
  'our-stalls': 'Nos étals',
  'timeslots': 'Horaires',
  'delivery-toaster': 'Votre livraison est prévue ',
  'restock-label': 'De retour %{date}%{hour}',
  'restock-label-V2': 'Retour %{date}%{hour}',
  'see-substitutes-products': 'Voir nos produits similaires',
  'free-product': '%{count} offert',
  'free-product_plural': '%{count} offerts',
  'add-product': 'Ajouter le produit',
  'remove-product': 'Retirer le produit',
  'picker-sold-out-tooltip': "Il n'est pas possible de commander plus pour ce créneau de livraison",
  'full': 'Complet',
  'discover': 'Je découvre',
  'excluded': 'Indisponible',
  'refresh-category': {
    'error-label': 'Une erreur est survenue lors du chargement',
    'empty-label': 'Cette catégorie est vide',
    'details':
      'Pour cette catégorie, nous ne disposons plus de produits pour votre jour de livraison.',
    'empty-button': "Revenir à l'accueil",
    'error-button': 'Recharger la page',
  },
  'required': ' Obligatoire',
  'today': 'Aujourd’hui',
  'dialog-confirm-button': "J'ai compris !",
  'free': 'Offert',
  'attributes': {
    'true': 'Oui',
    'false': 'Non',
    'N/A': 'Aucun',
    'undefined': 'Aucun(e)',
  },
};

import styled from 'styled-components';

import { Waves as BasedWaves } from '~/src/common/components/Logo';

export const IN_PROGRESS_ORDERS_BANNER_HEIGHT = 48;

export const Waves = styled(BasedWaves)`
  margin-bottom: 0;
`;

export const HomeTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 16px;

  & > h1 {
    text-align: center;
    margin: 16px 0 0;
  }
`;

export const FooterContentContainer = styled.div`
  margin-top: 58px;
  margin-bottom: 48px;
`;

export default {
  'modal-success-message': 'Vos informations ont bien été sauvegardées',
  'personal-informations-section': {
    title: 'Informations personnelles',
    form: {
      'firstname': { label: 'Prénom *', placeholder: 'ex: Matthieu' },
      'name': { label: 'Nom *', placeholder: 'ex: Dupont' },
      'email': {
        label: 'Email *',
        placeholder: 'ex: matthieu.dupont@gmail.com',
      },
      'phone': { label: 'Téléphone *', placeholder: 'ex: 06 12 34 56 78' },
      'submit-button': 'Modifier mes informations',
    },
  },
  'password-section': {
    'title': 'Changement de mot de passe',
    'form': {
      'current-password': {
        label: 'Mot de passe actuel *',
        placeholder: 'Mot de passe actuel',
      },
      'new-password': {
        label: 'Nouveau mot de passe *',
        placeholder: 'Nouveau mot de passe',
      },
      'submit-button': 'Enregistrer mon mot de passe',
    },
    'server-error': {
      message: 'Mot de passe actuel incorrect',
    },
  },
};

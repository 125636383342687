/* eslint-disable no-fallthrough */
import I18n from '~/src/common/services/I18n';
import {
  Order,
  OrderProductPricing,
  PickupOrderDelivery,
  OrderProduct,
} from '~/src/common/typings/order';
import { isOrderStateDelivering, isOrderStateTerminated } from '~/src/common/utils/orders';
import {
  toLocalizedWeightUnit,
  formatPrice,
  getPriceGranularity,
  getPricePerWeight,
} from '~/src/common/utils/prices';
import { ListOrders200ItemsItem } from '~/src/queries/api-ecom/generated/api-ecom.schemas';

// ORDER STATE

export const isInTransit = (orderState: Order['state']) => {
  const deliveringStatus = ['inTransit', 'readyToDeliver'];

  return deliveringStatus.includes(orderState);
};

export const isStatusAcceptedByClient = (orderState: Order['state']) => {
  const terminatedStatus = ['cart', 'prepaid', 'prepaying'];

  return terminatedStatus.includes(orderState);
};

export const isStatusReady = (orderState: Order['state']) => {
  return orderState === 'ready';
};

export const isStatusPrepared = (orderState: Order['state']) =>
  isOrderStateDelivering(orderState) ||
  isOrderStateTerminated(orderState) ||
  orderState === 'prepared';

type VatTotals = Record<
  string,
  {
    vat: number;
    vatRate: number;
    letter: string;
  }
>;

export const getVATsTotals = (orderPrice: Order['price']) => {
  let nextLetter = 65;

  return orderPrice?.fees.reduce<VatTotals>((acc, fee) => {
    const letter = String.fromCharCode(nextLetter);

    nextLetter += 1;

    return {
      ...acc,
      [fee.code]: {
        ...fee,
        letter,
      },
    };
  }, {});
};

export function getOrderProductVatLetterFromTotals(vatTotals: VatTotals, product: OrderProduct) {
  const code = product.quotation.price.vatNature?.code;
  if (!code) return undefined;
  return vatTotals?.[code]?.letter;
}

export const getStatusLabel = (
  state: ListOrders200ItemsItem['state'],
  mode: ListOrders200ItemsItem['delivery']['mode'],
) => {
  let statusLabel = '';
  const isPickup = mode === 'collect' || mode === 'drive';

  switch (state) {
    case 'prepaying':

    case 'prepaid':
      statusLabel = I18n.t('orders.status.accepted-by-client');

      break;

    case 'preparing':

    case 'prepared':

    case 'approvedByClient': {
      statusLabel = I18n.t('orders.status.preparing');

      break;
    }

    case 'cancelled':
      statusLabel = I18n.t('orders.status.cancelled');

      break;

    case 'ready':
      statusLabel = I18n.t('orders.status.ready');

      break;

    case 'cart':

    case 'inTransit':

    case 'readyToDeliver':
      statusLabel = isPickup
        ? I18n.t('orders.status.pickup-ready')
        : I18n.t('orders.status.delivering');

      break;

    default:
      statusLabel = I18n.t('orders.status.terminated');

      break;
  }

  return statusLabel;
};

export const isPickupDelivery = (
  delivery: Pick<ListOrders200ItemsItem['delivery'], 'deliveryZone' | 'mode'>,
): delivery is PickupOrderDelivery =>
  delivery.deliveryZone?.pickupType === 'collect' || delivery.deliveryZone?.pickupType === 'drive';

// ORDER PRODUCT

const formatOrderProductUnit = (product: OrderProduct) => {
  const unit: string | null = product.pricing.sellPrices.perGranularity?.unit ?? null;

  if (unit && unit !== 'count') return unit;

  const count = product.real.count ?? 1;

  if (!product.granularity) return I18n.t('orders-screen.products.piece', { count });

  return (count > 1 ? product.granularity.plural : product.granularity.singular) ?? null;
};

const formatOrderProductWeight = (
  perWeightUnit: OrderProductPricing['sellPrices']['perWeightUnit'],
  count: number,
) => {
  if (!perWeightUnit?.value || !perWeightUnit?.unit || perWeightUnit?.unit === 'count') {
    return '';
  }

  return `${count} x ${toLocalizedWeightUnit({
    weight: perWeightUnit.value,
    weightUnit: perWeightUnit.unit,
  })}`;
};

/*
 * Display the product quantity reflecting its 'selling pattern' (ex: 4 x 400g / 5kg / 5 pieces etc.)
 * */
export const getOrderProductGranularityQuantity = (
  pricing: OrderProduct['pricing'],
  count: number,
) => {
  const { sellPrices } = pricing;

  if (sellPrices?.perWeightUnit != null) {
    if (pricing.sellPrices.perGranularity?.main) {
      return count.toString();
    }

    return formatOrderProductWeight(sellPrices.perWeightUnit, count);
  }

  return null;
};

export function getOrderProductPriceDetails(product: OrderProduct) {
  const itemPrice = formatPrice(product.quotation2.count.itemPrice);

  const granularity = getPriceGranularity(product.quotation2.count.itemDefinition, {
    displayWeight: true,
  });

  const pricePerWeight = getPricePerWeight(product.quotation2.weight);
  const pricePerWeightLabel = pricePerWeight ? ` (${pricePerWeight})` : '';

  return `${itemPrice} ${granularity} ${pricePerWeightLabel}`;
}

function getOrderProductReplacementsMap(products: OrderProduct[]) {
  return products.reduce<{ [key: string]: OrderProduct }>((acc, product) => {
    if (!product.isReplacementFor) return acc;

    return { ...acc, [product.isReplacementFor]: product };
  }, {});
}

export function isOrderProductMissing(product: OrderProduct) {
  return product.real?.count === 0 && product.real?.weight === 0;
}

export function isOrderProductOffered(product: OrderProduct) {
  // Un produit offert représente une entrée dans les produits de l'order
  // Ainsi si la commande présente 1 produit offert et un produit identique acheté alors il y aura 2 produits distincts
  // au niveau de la commande et donc 2 lignes affichées
  return product.quotation2.count.freeQuantity > 0 || product.isGift;
}

export const isOrderProductSoldPerGranularity = (product: OrderProduct) =>
  Boolean(product.pricing.sellPrices.perGranularity?.main);

export function isOrderProductQuantityErrored(product: OrderProduct) {
  const isCountable = isOrderProductSoldPerGranularity(product);
  const isMissing = isOrderProductMissing(product);

  if (product.real?.price == null) return false;

  if (isMissing || isCountable) return product.real.count !== product.quotation2.count.quantity;

  return product.real.weight !== product.quotation2.weight?.weight;
}

export function getOrderProductQuantityLabel(product: OrderProduct) {
  const isMissing = isOrderProductMissing(product);
  const isCountable = isOrderProductSoldPerGranularity(product);

  const real = product.real ?? null;

  if (isCountable && !isMissing)
    return getOrderProductGranularityQuantity(product.pricing, real?.count);

  const { itemDefinition } = product.quotation2.count;

  // MDV4
  if (itemDefinition.type === 'arbitraryQuantity')
    return toLocalizedWeightUnit({
      weight: real?.weight,
      weightUnit: itemDefinition.weight?.unit,
    });

  // MDV3
  const unit = formatOrderProductUnit(product) ?? '';
  const weight = toLocalizedWeightUnit({
    weight: real?.weight,
    weightUnit: product.quotation2.weight?.unit,
  });

  return `${real?.count} ${unit} (${weight})`;
}

export const getOrderProductPrice = ({
  price,
  product,
  vatLetter,
}: {
  price: number;
  product: OrderProduct;
  vatLetter?: string;
}) =>
  `${formatPrice(price)} ${!isOrderProductSoldPerGranularity(product) ? '**' : ''}${
    vatLetter != null ? `(${vatLetter})` : ''
  }`;

export function separateOrderProductReplacements(products: OrderProduct[]) {
  return {
    originals: products.filter(p => !p.isReplacementFor),
    replacements: getOrderProductReplacementsMap(products),
  };
}

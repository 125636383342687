import React from 'react';

import I18n from '~/src/common/services/I18n';
import { logger } from '~/src/common/services/Logger';

const REPORT_DIALOG_OPTS = {
  title: I18n.t('error-boundary.title'),
  subtitle: I18n.t('error-boundary.subtitle'),
  subtitle2: '',
  labelName: I18n.t('error-boundary.label-name'),
  labelEmail: I18n.t('error-boundary.label-email'),
  labelComments: I18n.t('error-boundary.label-comments'),
  labelSubmit: I18n.t('error-boundary.label-submit'),
  labelClose: I18n.t('error-boundary.label-close'),
  errorGeneric: I18n.t('error-boundary.error-generic'),
  errorFormEntry: I18n.t('error-boundary.error-form-entry'),
  successMessage: I18n.t('error-boundary.success-message'),
};

interface Props {
  children: React.ReactNode;
}

class ErrorBoundary extends React.Component<Props> {
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const stack = errorInfo.componentStack ?? error.stack;
    logger.error(error.message, {
      originalError: Object.assign(error, { stack }),
      react: { componentStack: errorInfo.componentStack },
    });
    if ('showReportDialog' in logger) {
      logger.showReportDialog(REPORT_DIALOG_OPTS);
    }
  }

  render() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/prop-types
    return this.props.children;
  }
}

export default ErrorBoundary;

export default {
  'back': 'Retourner à mes adresses',
  'address-title-field': {
    label: "Titre de l'adresse",
    placeholder: 'Exemple : Maison',
  },
  'address-field': {
    'label': 'Adresse',
    'add-address': 'Ajouter une adresse',
    'modify-address': "Modifier l'adresse",
  },
  'address-supplement-field': {
    label: 'Instructions pour le livreur',
    placeholder: 'Ex : digicode 1234, 3ème étage, 2ème porte à droite',
  },
  'lastname-field': { label: 'Nom *' },
  'firstname-field': { label: 'Prénom *' },
  'phone-field': { label: 'Téléphone *' },
  'add-delivery-address': 'Ajouter une adresse de livraison',
  'edit-delivery-address': 'Enregistrer les modifications',
};

import { NextRouter } from 'next/router';

export const ENHANCED_ECOMMERCE_KEYS = [
  'click',
  'detail',
  'checkout',
  'purchase',
  'remove',
  'add',
  'refund',
] as const;

// FIXME
// Des informations analytics particulières sont envoyées pour la page "Préferences"
export const PAGE_VIEWS = {
  event: 'pageView',
  pageType: {
    homePage: 'HomePage',
    categoryPage: 'CategoryPage',
    subcategoryPage: 'SubcategoryPage',
    resultPage: 'ResultPage',
    productPage: 'ProductPage',
    commitments: 'MarketingPage',
    termsAndConditions: 'TCPage',
    editAccount: 'EditAccountPage',
    editAccountAddresses: 'EditAccountAddressesPage',
    addAccountAddresses: 'AddAccountAddressesPage',
    listAccountAddresses: 'ListAccountAddressesPage',
    listAccountOrders: 'ListAccountOrdersPage',
    listAccountPaymentCards: 'ListAccountPaymentCardsPage',
    landingPage: 'LandingPage',
    errorDialog: 'ErrorDialog',
  },
};

export const IDS = {
  searchResults: 'Search Results',
  lists: 'Lists',
  basket: 'Basket',
  productPage: 'Product Page',
  listingPage: 'Listing Page',
  promotions: 'Promotions',
  checkout: 'Checkout',
  xSellCheckout: 'checkout cross sell',
  xSellMain: 'main product cross sell',
  headerMarketing: 'header marketing',
};

export const getPageType = (url: string, query: NextRouter['query']) => {
  if (query.q != null) return PAGE_VIEWS.pageType.resultPage;

  if (url === '/' || url === '/reset-password') return PAGE_VIEWS.pageType.homePage;

  if (url === '/account/addresses') return PAGE_VIEWS.pageType.listAccountAddresses;

  if (url === '/account/me') return PAGE_VIEWS.pageType.editAccount;

  if (url === '/landing') return PAGE_VIEWS.pageType.landingPage;

  // The page should not be tracked
  return null;
};

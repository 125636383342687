export default {
  title: 'Nos newsletters et alertes',
  info: 'Vitaminez votre semaine en choisissant ce que vous préférez recevoir.',
  optin: {
    mail: {
      'aria-label': {
        accepted: 'communication par email acceptées',
        denied: 'communication par email refusées',
      },
      'name': 'Email',
      'description':
        'Je souhaite recevoir des offres, des idées de recettes et les nouveaux arrivages en avant-première par email.',
    },
    sms: {
      'aria-label': {
        accepted: 'communication par sms acceptées',
        denied: 'communication par sms refusées',
      },
      'name': 'SMS',
      'description':
        'Je souhaite recevoir des offres, des idées de recettes et les nouveaux arrivages en avant-première par SMS.',
    },
  },
};

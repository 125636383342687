import styled, { css } from 'styled-components';

import Button from '~/src/common/components/Button';
import { FAMILY_MENU_HEIGHT } from '~/src/common/components/Navigation/constants';
import { Grid } from '~/src/common/components/ProductsGrid/layout';
import { H1, H2 } from '~/src/common/components/Typography';
import { getMediaQuery } from '~/src/common/utils/style';
import {
  NAV_HEIGHT,
  TREEVIEW_MARGIN,
  TREEVIEW_WIDTH,
  breakpoints,
  media,
} from '~/src/styles-constants';

const TOP_NAV_POSITION = NAV_HEIGHT + FAMILY_MENU_HEIGHT;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;
  margin: 8px 0;
  padding: 0;

  h1,
  h2 {
    margin: 0;
    padding: 0;
  }
`;

const SubcategoryTitle = css`
  display: inline-flex;
  align-items: center;
  gap: 16px;
  margin: 0;
  margin-top: 0;
  margin-bottom: 8px;

  a {
    color: ${({ theme }) => theme.palette.common.BLACK};
  }
`;

export const SubcategoryMainTitle = styled(H1)`
  ${SubcategoryTitle}
  line-height: 40px;
  margin-bottom: 16px;
`;

export const SubcategorySecondTitle = styled(H2)`
  ${SubcategoryTitle};
  margin-bottom: 8px;
`;

export const SubcategoryContainer = styled.div`
  padding-bottom: 16px;

  ${media.XL`
    padding-bottom: 32px;
  `}
`;

export const ContentContainer = styled.div`
  @media ${getMediaQuery(0, breakpoints.XL)} {
    > div {
      margin-top: 8px;
    }
  }

  ${media.XL`
    display: grid;
    grid-template-columns: auto 1fr;
    margin-bottom: 16px;
  `}
`;

export const CategoryTreeViewContainer = styled.nav`
  display: none;

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  scrollbar-width: none; /* Firefox */

  ${media.XL`
    display: block;
    position: sticky;
    align-self: start;
    width: ${TREEVIEW_WIDTH}px;
    overflow: auto;
    height: calc(100vh - ${TOP_NAV_POSITION}px);

    margin-right: ${TREEVIEW_MARGIN}px;
  `};
`;
export const StyledButton = styled(Button)`
  width: 100%;
  font-weight: normal;
  margin: 0 !important;
  border-radius: 0;

  > div {
    justify-content: flex-start;
  }

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    padding: 0 !important;
    text-decoration: none !important;
    font-size: 14px;
    line-height: 24px;
  }

  span:hover {
    color: ${({ theme }) => theme.palette.common.PRIMARY_HOVER};
  }
`;

export const CategoryTreeViewList = styled.ol`
  position: relative;
  width: 100%;
  padding-left: 16px;
  white-space: nowrap;

  list-style: none;

  li {
    position: relative;
    width: 100%;
    font-size: 14px;
    line-height: 24px;

    > span:first-child {
      display: none;
    }
  }

  li[aria-current='location'] {
    ${StyledButton} {
      span {
        font-weight: 500;
      }
    }

    line-height: normal;
    font-size: 16px;

    &::before {
      content: '';
      position: absolute;
      left: -16px;
      top: 8px;
      display: block;
      background-color: ${({ theme }) => theme.palette.primary};
      height: 8px;
      width: 8px;
      border-radius: 8px;
    }
  }

  li:not(:last-child) {
    ${StyledButton} {
      padding-bottom: 8px;
    }
  }
`;

export const CategorySkeletonWrapper = styled(ContentContainer)`
  margin-bottom: 32px;

  ${media.XL`
  margin-top: 12px;
`}
`;

export const SkeletonSubCategory = styled.div`
  width: 100%;
  margin-right: 32px;
`;

export const TextLineBig = styled.div`
  background: ${({ theme }) => theme.palette.common.PLACEHOLDER};

  height: 25px;
  margin-top: 18px;
  margin-bottom: 20px;
  width: 65%;
`;

export const TextLineMedium = styled.div`
  background: ${({ theme }) => theme.palette.common.PLACEHOLDER};

  height: 20px;
  width: 25%;
  margin-top: 16px;
`;

export const TextLineSmall = styled.div<{ width?: string }>`
  background: ${({ theme }) => theme.palette.common.PLACEHOLDER};

  height: 15px;
  width: ${({ width }) => width ?? '45%'};
  margin-top: 23px;
`;

export const SkeletonProductCardGrid = styled(Grid)`
  margin-top: 20px;
  margin-bottom: 40px;
`;

export const SkeletonMobileMenu = styled.div`
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  margin-top: 0 !important;
`;

export const SkeletonCategoryMobileMenu = styled.div`
  display: flex;
  overflow: auto;
  padding: 12px 16px;

  ${media.XL`
    display: none;
  `}

  margin: 0 !important;

  div {
    background-color: ${({ theme }) => theme.palette.common.PLACEHOLDER};
    width: 100px;
    height: 20px;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`;

export const SkeletonSubCategoryMobileMenu = styled.div`
  display: flex;
  overflow: auto;
  padding: 8px 12px;
  margin-top: 0 !important;
  height: 40px;
  max-width: 100%;

  background-color: ${({ theme }) => theme.palette.common.PLACEHOLDER};

  &::-webkit-scrollbar {
    display: none;
  }

  ${media.XL`
    display: none;
  `}

  div {
    background-color: ${({ theme }) => theme.palette.common.WHITE};
    width: 90px;
    height: 24px;
    border-radius: 10px;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
`;

export const FooterContentContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 26px;
`;

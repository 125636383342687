import { useRouter } from 'next/router';

import { useCartWithTimeslot } from '~/src/common/hooks/use-cart-with-timeslot';
import { useAuthenticated } from '~/src/common/hooks/user/use-authenticated';
import { removeAllModals, useNiceModal } from '~/src/common/services/ModalsManager';
import Tracker from '~/src/common/services/Tracker';
import { getArticlesCount } from '~/src/common/utils/cart';
import { isOrderStateDelivering } from '~/src/common/utils/orders';
import { useCurrentOrders } from '~/src/queries/api-ecom/generated/api-ecom';
import { BannerMessage } from '~/src/screens/AccountManagementModal';

const useNavigationActions = () => {
  const router = useRouter();
  const isAuthenticated = useAuthenticated();
  const cart = useCartWithTimeslot();

  const { show: openUserPersonalListsModal } = useNiceModal('user-personal-lists-modal');
  const { show: openCartModal } = useNiceModal('cart-modal');
  const accountManagementModal = useNiceModal('account-management-modal');

  const { data: ordersData } = useCurrentOrders({
    query: {
      enabled: isAuthenticated && !router.pathname.startsWith('/account/'),
      staleTime: Infinity,
    },
  });
  const hasOngoingOrders =
    (ordersData?.items?.filter(
      order =>
        isOrderStateDelivering(order.state) || ['preparing', 'prepaid'].includes(order.state),
    ).length || 0) > 0;
  const cartProductsQuantity = getArticlesCount(cart);

  const eventName = 'user navigation menu';

  const handleLogoClick = () => {
    Tracker.sendEvent(eventName, { 'navigation text': 'logo' });

    if (router.pathname === '/') {
      removeAllModals();
    }
  };

  const handleRecipesClick = () => {
    Tracker.sendEvent(eventName, { 'navigation text': 'recettes' });

    void router.push('/categorie/nos-recettes');
  };

  const handleListsClick = () => {
    if (isAuthenticated) {
      void openUserPersonalListsModal();
    } else {
      void accountManagementModal.show({
        banner: { icon: 'heart-normal', message: BannerMessage.DisplayBookmarksList },
      });
    }

    Tracker.sendEvent(eventName, { 'navigation text': 'lists' });
  };

  const handleAccountClick = () => {
    Tracker.sendEvent(eventName, { 'navigation text': 'account' });

    if (isAuthenticated) {
      void router.push('/account/orders');
    } else {
      void accountManagementModal.show();
    }
  };

  const handleCartClick = () => {
    void openCartModal();

    Tracker.sendEvent('user navigation basket');
  };

  return {
    hasOngoingOrders,
    cartProductsQuantity,
    handleRecipesClick,
    handleLogoClick,
    handleListsClick,
    handleAccountClick,
    handleCartClick,
  };
};

export default useNavigationActions;

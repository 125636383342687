export default {
  'empty': "Vous n'avez pas encore passé de commande",
  'cancellation-done-message': 'Votre commande a bien été annulée',
  'products': {
    'piece': 'pièce',
    'piece_plural': 'pièces',
    'free': 'Offert',
    'free_plural': 'Offerts',
    'x-free': '%{count} offert',
    'x-free_plural': '%{count} offerts',
  },
};

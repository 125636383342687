import constants from '~/src/constants';

export default {
  'know-more': 'En savoir plus',
  'general-values': {
    title: 'nos engagements',
    selection: {
      'title': 'une sélection entre de bonnes mains',
      'content-1':
        'Les délicieux produits Grand Frais livrés chez vous à Paris, vous en rêviez ? Avec',
      'content-2':
        'c’est désormais possible ! Chaque jour, nos équipes sélectionnent minutieusement pour vous, les meilleures variétés de saison selon vos goûts : fruits, légumes, fromages, poissons, viandes… Régalez-vous en toute confiance et au meilleur prix.',
    },
    choice: {
      title: 'une purée de petits choix',
      content:
        "Envie de pommes de terre ? Vous les prendrez grenaille, prunelle ou  Blue Belle ? Chaque produit dispose de multiples références pour répondre à toutes vos envies, c'est aussi plus de 2000 produits frais qui varient au ﬁl des saisons, pour satisfaire tous les goûts de la maison !",
    },
    discount: {
      title: 'des promotions belles à croquer !',
      content:
        "Des prix hauts comme 3 pommes toute l'année grâce à nos promotions rythmées par des arrivages quotidiens. Ces prix sont le fruit d'une relation de confiance entretenue avec nos producteurs, agriculteurs, maraîchers passionnés, pêcheurs et affineurs.",
    },
    delivery: {
      title: 'Livraison ultra fraîche et sur-mesure !',
      content:
        'En tant qu’experts de l’ultra-frais, notre mission est de vous livrer le meilleur des produits frais avec fiabilité et simplicité selon vos besoins. Que ce soit pour une livraison le lendemain ou dans 5 jours, nos produits sont bichonnés dans 5 espaces de températures différentes pour vous garantir une qualité irréprochable.',
    },
  },
  'cheese-value': {
    'title': 'Paroles de votre Fromager!',
    'local-production':
      'Pour votre plus grand plaisir, nos acheteurs spécialistes sélectionnent des <strong>fromages de qualité fabriqués par des petits producteurs français passionnés !</strong> C’est leurs savoir-faire traditionnels qui fait l’exception française en matière de fromages ! Nous privilégions des relations de long termes avec nos producteurs partenaires pour vous proposer le meilleur de nos terroirs.',
    'certified-production':
      "La valorisation du territoire français, c’est aussi mettre en avant <strong>les talents de chacun</strong>. Nous sommes fiers de vous proposer toute la crème des produits français protégés par les signes officiels de qualité <strong>AOP* et IGP**</strong>. Nous sélectionnons également les meilleurs produits en provenance d'Italie, de Grèce, d'Espagne, de Hollande, du Royaume-Uni...",
    'taste':
      "Parce que le goût est notre objectif, nous vous proposons <strong>des fromages riches en saveurs</strong> et n'hésitons pas à <strong>affiner</strong> ceux qui en ont besoin.",
  },
  'green-grocer-value': {
    'title': 'Paroles de votre Primeur!',
    'farmers-partnership':
      'Depuis de longues années, pour vous proposer des fruits et des légumes qui ont du goût, <strong>votre primeur travaille main dans la main avec ses producteurs partenaires.</strong><br><br>Bien évidemment, pour y parvenir jour après jour, saison après saison, nous sélectionnons des producteurs qui partagent <strong>la même passion du bon et du goût que nous !</strong>',
    'various-species': `Parce qu'il ne suffit pas qu'une tomate* soit charnue et côtelée pour en faire une cœur de bœuf ou encore que toutes les variétés d'abricot** ne vous rappelleront pas les fruits dans lesquels vous mordiez chez vos grands-parents, <strong>chaque jour nos primeurs spécialistes s'activent</strong> pour vous proposer le meilleur d'environ <strong>200 variétés de légumes et de fruits.</strong><br><br>Tout au long de l'année, <strong>cela fait plus de 1000 variétés sur notre étal ${constants.BRAND_NAME}</strong>. Chacune est issue d'un travail de sélection réalisé avec nos producteurs pour vous donner le choix entre des légumes et des fruits qui ont du goût. <br><br>*Nous vous donnons rendez-vous <strong>à la fin du printemps</strong> pour déguster notre tomate <strong>variété Cauralina issue de cultures française de pleine terre</strong>.<br><br>**Pour ce qui est du goût de nos abricots, nous sommes impatients que vous dégustiez <strong>notre sélection labellisée Cœur de cueillette.</strong>`,
    'responsible-agricultural-practices':
      'Nous croyons à une <strong>solution agricole de long terme</strong> basée sur un modèle à l’équilibre entre les méthodes culturales conventionnelles et biologiques. Nous avons donc fait le choix de privilégier une offre large en fruits et légumes issus d’une <strong>agriculture raisonnée plutôt que biologique</strong> avec, comme objectif, de réduire drastiquement l’usage des produits phytosanitaires et de faire disparaître les résidus dans nos fruits et légumes.<br><br>Ainsi <strong>depuis plus de 10 ans</strong>, nous nous impliquons dans une approche pérenne auprès de nos producteurs partenaires pour vous proposer des fruits et légumes issus d’<strong>une agriculture aux pratiques plus responsables et aussi plus écologiques</strong>. Pour cela, nous échangeons tous les jours avec nos producteurs non seulement sur le choix des variétés mais également sur <strong>les meilleures manières de les cultiver</strong>. Sans être bio pour autant, nous sommes convaincus que cette démarche va dans le bon sens à la fois pour nos producteurs <strong>mais aussi pour vous !</strong>',
    'products-freshness':
      "Nous travaillons main dans la main avec nos producteurs pour choisir le bon moment de récolte ou de cueillette pour tous nos légumes et nos fruits. Ainsi nous n'hésitons pas à <strong>faire cueillir nos fruits en plusieurs fois</strong>, afin de <strong>respecter leur rythme de maturation</strong> et pour vous les proposer quand ils sont mûrs, simplement.<br><br>Nous nous approvisionnons de tous nos légumes et tous nos fruits <strong>tous les jours</strong> où nous vous les livrons : c'est cela notre exigence <strong>pour vous garantir la plus grande fraîcheur</strong> et les meilleurs produits possible.",
  },
  'caterer-value': {
    'title': 'Paroles de votre Traiteur!',
    'taste':
      '<strong>La qualité de nos produits est une priorité</strong> mais elle ne se fait jamais aux dépens du goût de nos recettes. Bien manger c’est aussi <strong>se faire plaisir !</strong>',
    'seasons-concerned':
      'Respecter <strong>les saisons et les produits</strong> qu’elles nous offrent est essentiel. C’est pourquoi notre sélection est <strong>souvent renouvelée !</strong>',
    'cookery-recipe':
      'Pour vous éviter la routine alimentaire et émerveiller quotidiennement vos papilles, nous vous proposons <strong>un grand nombre de recettes originales.</strong>',
  },
};

import dayjs from '~/src/common/services/Date';
import { DeliveryMode } from '~/src/common/typings/cart';
import { DeliverySlots200DeliveryZonesItemDeliveryPricesItem } from '~/src/queries/api-ecom/generated/api-ecom.schemas';

// obligé de mettre dans un fichier à part car dans le utils ca plante au build (window not defined)
export const getDeliveryIconName = (deliveryMode?: DeliveryMode | null) => {
  switch (deliveryMode) {
    case 'collect':
      return 'Move-walk';

    case 'drive':
      return 'Move-drive';

    default:
      return 'Move-delivery';
  }
};

export const getDeliveryLabelName = (deliveryMode?: DeliveryMode | null) => {
  switch (deliveryMode) {
    case 'collect':
      return 'walk';

    case 'drive':
      return 'drive';

    default:
      return 'delivery';
  }
};

export const getDeliveryZoneTypeIconName = (deliveryZoneType: string) => {
  switch (deliveryZoneType) {
    case 'pickup':
    case 'remotePickup':
      return 'shop-normal';
    case 'onSitePickup':
      return 'sur-place';
    default:
      return 'marker-normal';
  }
};

/**
 * Trie les seuils par order de panier min croissant
 * Renvoie les seuils pertinents pour l'user => ceux qui incluent le min de commande ou sont au dessus
 * Ce tri doit en théorie être effectué côté BACK
 * TODO supprimer cet util et l'intégrer directement côté back
 * @param deliveryPrices les seuils de livraison
 * @param minOrderAmount le min de commande
 */
export const getDeliveryPricesAboveMinCartAmount = (
  deliveryPrices: DeliverySlots200DeliveryZonesItemDeliveryPricesItem[],
  minOrderAmount: number,
): DeliverySlots200DeliveryZonesItemDeliveryPricesItem[] => {
  const mappedDeliveryPrices = deliveryPrices.sort(
    (priceA, priceB) => priceA.minCartNetPrice - priceB.minCartNetPrice,
  );

  const deliveryPriceUnderMinOrderAmount = [...mappedDeliveryPrices]
    ?.reverse()
    .find(dp => dp.minCartNetPrice <= minOrderAmount);
  const deliveryPricesAboveMinOrderAmount =
    mappedDeliveryPrices?.filter(dp => dp.minCartNetPrice > minOrderAmount) || [];

  return deliveryPriceUnderMinOrderAmount
    ? [deliveryPriceUnderMinOrderAmount, ...deliveryPricesAboveMinOrderAmount]
    : deliveryPricesAboveMinOrderAmount;
};

export const isTimeslotValid = (timeSlot: { orderUntil: number }) =>
  dayjs().isBefore(timeSlot.orderUntil);

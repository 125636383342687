import { useEffect } from 'react';

import Router, { useRouter } from 'next/router';

import { TagManager, PAGE_VIEWS, getPageType } from '~/src/common/services/TagManager';
import Tracker, { getEventBrowseValues } from '~/src/common/services/Tracker';

const usePageTracking = () => {
  const router = useRouter();

  useEffect(() => {
    // asPath retourne le chemin affiché dans l'url avec également les query params (https://nextjs.org/docs/pages/api-reference/functions/use-router#router-object). Cependant dans notre cas on a besoin de récupérer uniquement le chemin.
    const url = router.asPath.split('?')[0];
    // Initial page load
    const initialPageType = getPageType(url, Router.router?.query ?? {});
    const { eventName: initialEventName, eventProperties: initialEventProperties } =
      getEventBrowseValues(url, Router.router?.query ?? {}) || {};

    // Allows to avoid tracking page handled by React-Router to be tracked twice
    if (initialPageType != null) {
      TagManager.setDataLayer({
        event: PAGE_VIEWS.event,
        PageType: initialPageType,
      });
    }

    if (initialEventName != null && initialEventProperties != null) {
      Tracker.sendViewEvent({
        eventName: initialEventName,
        eventProperties: initialEventProperties,
      });
    }
  }, [router.events, router.asPath]);
};

export default usePageTracking;

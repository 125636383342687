import { isObject } from '../../utils/guards';
import { pick } from '../../utils/object';

import { fetchOnBrowser } from './browser/fetch';
import { fetchOnServer } from './server/fetch';
import { Params } from './types';

// Sélection du bon fetcher, sans utiliser isServerSide pour aider le tree-shaking
const isoFetch = typeof window === 'undefined' ? fetchOnServer : fetchOnBrowser;

// Wrapper autour de la base pour inclure la requête dans les erreurs
export const fetch = async <T>(input: Params): Promise<T> =>
  isoFetch<T>(input).catch(err => {
    const req = pick(input, ['method', 'url', 'params', 'data']);
    const extra = isObject(err) && isObject(err.extra) && err.extra;
    throw Object.assign(err, { extra: { req, ...extra } });
  });

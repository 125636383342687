import { isObject, isString, isNumber } from '~/src/common/utils/guards';
import { isServerSide } from '~/src/common/utils/server';
import envConstants from '~/src/env-constants';

import type { User, ReportDialogOptions } from './Sentry';

// Local typings
type Extra = { [_: string]: unknown; accountId?: string; originalError?: unknown };
type Options = { sentryBreadCrumbCategory?: string };
type LogLevel = 'debug' | 'info' | 'warning' | 'error' | 'critical';
type LogParams = [message: string, data?: Extra, opts?: Options];
type LogMethod = (...params: LogParams) => void;
type LogHandler = (level: LogLevel, ...rest: LogParams) => void;
type LoggerObject = Record<LogLevel, LogMethod> & {
  setUser: (user: User | null) => void;
  showReportDialog: (opts: ReportDialogOptions) => void;
};

// Disable Sentry during tests and when running localy
const isTestOrIsLocal = envConstants.IS_TEST || envConstants.IS_LOCAL;
const isRunningOnline = typeof window !== 'undefined' && window.location.protocol === 'https:';
const isSentryEnabled = isRunningOnline && !isTestOrIsLocal && !!envConstants.SENTRY_DSN;

// Store events while waiting for Sentry to load
const queue: (() => void)[] = [];

// Create the logger that enqueues events
const Logger: LoggerObject = {
  // Reporting methods
  debug: (...args) => queue.push(() => Logger.debug(...args)),
  info: (...args) => queue.push(() => Logger.info(...args)),
  warning: (...args) => queue.push(() => Logger.warning(...args)),
  error: (...args) => queue.push(() => Logger.error(...args)),
  critical: (...args) => queue.push(() => Logger.critical(...args)),
  // Sentry utils
  setUser: (...args) => queue.push(() => Logger.setUser(...args)),
  showReportDialog: (...args) => queue.push(() => Logger.showReportDialog(...args)),
};

// Load and initialize Sentry
let isSentryImported = false;
const importSentryIntoLogger = async () => {
  // Sentry is only injected once
  if (isSentryImported) return;
  isSentryImported = true;

  // Skip init if SentryDsn is not set
  if (!envConstants.SENTRY_DSN) return;

  // Import Sentry and integrations asynchronously
  const { Sentry, Integrations } = await import('./Sentry');

  // Map logger.X to Sentry.Y
  const Mapping = {
    debug: 'debug',
    info: 'info',
    warning: 'warning',
    error: 'error',
    critical: 'fatal',
  } as const;

  Sentry.init({
    enabled: isSentryEnabled,
    dsn: envConstants.SENTRY_DSN,
    environment: envConstants.APP_TIER,
    release: envConstants.RELEASE_NAME,
    beforeSend: event => {
      // Ignore les erreurs provenant du bot de Slack (noms d'erreurs trop génériques pour être ignorées avec ignoreErrors)
      if (window.navigator.userAgent.indexOf('Slackbot-LinkExpanding') !== -1) return null;
      return event;
    },
    ignoreErrors: [
      // Nos erreurs à nous, mais qu'on peut sereinement ignorer
      // Les erreurs liées à la config des navigateurs / crawlers
      /The operation is insecure/i, // Security policy avec scripts externes
      /Blocked a frame with origin/i, // Injection frame externe (extensions, test tool, etc..)
      /Loading chunk ui-gdpr-\w+-web failed/i, // Didomi blocké par un adblocker
      /Failed to read the 'localStorage' property from 'Window'/i, // Navigation privée et/ou config du browser
      /Non-Error promise rejection captured with value: Object Not Found Matching Id/i, // Outlook (https://github.com/getsentry/sentry-javascript/issues/3440)
      /ResizeObserver loop limit exceeded/i, // Problème peu fréquent à l'impact faible et dont la reproduction est difficile: https://stackoverflow.com/a/50387233/7477752
      // Les scripts externes, sur lesquels on n'a pas de contrôle
      /(reading|evaluating).*persist_condition'/i, // HotJar
      /Object\._canDisplayBanner \[as canDisplayBanner\]/i, // AppsFlyer
      /reading '[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}'/i, // AppsFlyer
      /Non-Error promise rejection captured with value: Could not find .+ in window/i, // GTM loaders
      // Erreurs non actionnables
      /<unknown module>/i,
      /IMUID is not defined/i,
      /Failed to execute 'removeChild' on 'Node'/i,
      /reading '(loaded|outputCurrentConfiguration)'/i,
    ],
    allowUrls: [
      /https:\/\/([\w.-]+\.)mon-marche.fr/i, // Domaines mon-marche.fr, tout sous-domaines confondus
    ],
    normalizeDepth: 6,
    attachStacktrace: true,
    tracesSampleRate: envConstants.IS_PRODUCTION ? 0.1 : 1,
    integrations: defaultIntegrations => [
      ...defaultIntegrations,
      new Integrations.ExtraErrorData({ depth: 6 }),
    ],
  });

  const handleBreadcrumb: LogHandler = (_level, message, data, opts) => {
    Sentry.addBreadcrumb({ category: opts?.sentryBreadCrumbCategory ?? 'log', message, data });
  };

  const handleException: LogHandler = (level, message, data = {}) => {
    Sentry.withScope(scope => {
      const fingerprintKeys = [level, message];
      const { accountId, originalError } = { ...data };
      const setTag = (key: string, val?: unknown) => {
        if (val != null) scope.setTag(key, String(val));
      };

      // Always save the account in tags, default to env if not available
      setTag('account_id', accountId ?? envConstants.TARGET_CUSTOMER);

      // Handle the original error, if it's defined
      if (isObject(originalError)) {
        // Extract values of interest from the original error
        const config = isObject(originalError.config) ? originalError.config : {};
        const request = isObject(originalError.request) ? originalError.request : {};
        const response = isObject(originalError.response) ? originalError.response : {};

        // Extract status code and URL
        const statusResponse = response.status;
        const statusRequest = request.status_code;
        const statusRData = isObject(response.data) ? response.data.status : null;
        const statusCode = statusResponse ?? statusRequest ?? statusRData;

        const url = request?.url ?? config?.url;

        // Update tags and fingerprints
        if (isString(url)) setTag('url', url);
        if (isString(statusCode) || isNumber(statusCode)) {
          setTag('status_code', statusCode);
          fingerprintKeys.push(String(statusCode));
        }
      }

      // Set error level and fingerprint keys
      scope.setLevel(Mapping[level]);
      scope.setFingerprint(fingerprintKeys);

      // Send error to sentry
      if (originalError instanceof Error) {
        const { originalError: _, ...rest } = data;
        scope.setContext('Log Message', { message, ...rest });
        Sentry.captureException(originalError);
      } else {
        scope.setContext('Log Message', data);
        Sentry.captureMessage(message);
      }
    });
  };

  // Overwrite the logger
  Logger.debug = (...rest) => handleBreadcrumb('debug', ...rest);
  Logger.info = (...rest) => handleBreadcrumb('info', ...rest);
  Logger.warning = (...rest) => handleException('warning', ...rest);
  Logger.error = (...rest) => handleException('error', ...rest);
  Logger.critical = (...rest) => handleException('critical', ...rest);
  Logger.setUser = (...rest) => Sentry.setUser(...rest);
  Logger.showReportDialog = (...rest) => Sentry.showReportDialog(...rest);

  // Unqueue all saved events
  while (queue.length) queue.shift()?.();
};

// Wrap each method with a function that will automatically
// trigger the load/setup of Sentry (and the logger upgrade)

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withSentry = <F extends (...args: any[]) => unknown>(fn: F): F => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return ((...args) => [importSentryIntoLogger(), fn(...args)][1]) as F;
};

Logger.debug = withSentry(Logger.debug);
Logger.info = withSentry(Logger.info);
Logger.warning = withSentry(Logger.warning);
Logger.error = withSentry(Logger.error);
Logger.critical = withSentry(Logger.critical);
Logger.setUser = withSentry(Logger.setUser);
Logger.showReportDialog = withSentry(Logger.showReportDialog);

// Load Sentry browser in background (except when running tests)
// Previously, Sentry was loaded only when an error was caught or logged
// This caused the loss of users interactions within the app before the crash
// Preloading will correct this, and give us more infos on what caused the error

if (!isServerSide() && !envConstants.IS_TEST) void importSentryIntoLogger();

export default Logger;

export default {
  'welcome': 'Bienvenue %{userFirstname}!',
  'category-name': 'Livraison courses',
  'drive-mode': 'Clic & Cueillette',
  'collect-mode': 'Clic & Cueillette',
  'delivery-mode': 'Livraison',
  'meta-description':
    'Vos courses en ligne livrées à domicile : Profitez de notre engagement envers la fraîcheur et la qualité, 7J/7. Service de livraison rapide et pratique',
  'order': {
    'pickup-ready': 'Commande disponible',
    'accepted': 'Commande acceptée',
    'preparing': 'Commande en préparation',
    'delivering': 'Commande en livraison',
    'several-in-progress': 'Vous avez %{count} commande en cours',
    'several-in-progress_plural': 'Vous avez %{count} commandes en cours',
  },
  'quick-buttons': {
    'description':
      'Retrouvez ici vos dernières commandes et les produits que vous achetez le plus souvent.',
    'last-orders': 'Dernières commandes',
    'frequent-purchases': 'Achats fréquents',
  },
  'last-orders': {
    'title': 'Mes dernières commandes',
    'banner': 'Achetez à nouveau les produits de vos dernières commandes.',
    'order-title': 'Commande du %{date}',
    'order-cancelled': 'Annulée',
    'no-orders-title': 'Aucune commande',
    'no-orders': 'Passez une commande pour<br/> bénéficier de ce service',
    'ok': "J'ai compris",
    'nb-products': '%{count} produit',
    'nb-products_plural': '%{count} produits',
  },
  'frequent-purchases': {
    'title': 'Achats fréquents',
    'banner': 'Retrouvez ici les produits que vous achetez le plus souvent.',
    'no-products-title': 'Aucun achats fréquents',
    'no-products': 'Passez une commande pour<br/> bénéficier de ce service',
  },
  'catalog-swiper': {
    'move-left': 'Déplacer la liste des produits à gauche',
    'move-right': 'Déplacer la liste des produits à gauche',
  },
};

import React, { useState } from 'react';

import I18n from '~/src/common/services/I18n';
import Zendesk from '~/src/common/services/Zendesk';
import FacebookIcon from '~/src/screens/App/Footer/images/facebook.svg';
import InstagramIcon from '~/src/screens/App/Footer/images/instagram.svg';
import LinkedinIcon from '~/src/screens/App/Footer/images/linkedin.svg';
import { sendFooterNavigationEvent } from '~/src/screens/App/Footer/utils';

import {
  LinksSection,
  SectionTitle,
  ToggleSectionButton,
  LinksInnerSection,
  OpeningHours,
  SocialIconsContainer,
  ContactList,
  StyledLink,
} from '../layout';

const FACEBOOK_URL = 'https://www.facebook.com/monmarche/';
const INSTAGRAM_URL = 'https://www.instagram.com/mon.marche.fr/';
const LINKEDIN_URL = 'https://www.linkedin.com/company/mon-marche/';

const ContactUsSection = () => {
  const [isSectionOpened, setSectionOpened] = useState(false);
  const toggleSectionOpened = () => setSectionOpened(!isSectionOpened);

  const onChatClick = () => {
    sendFooterNavigationEvent('par chat');
    Zendesk.open();
  };

  return (
    <LinksSection>
      <SectionTitle onClick={toggleSectionOpened}>
        {I18n.t('footer.contact-us')}
        <ToggleSectionButton
          $isOpened={isSectionOpened}
          variant="transparent"
          icon="Chevron-down"
        />
      </SectionTitle>
      <LinksInnerSection $isOpened={isSectionOpened}>
        {I18n.t('footer.openings')}
        <OpeningHours>{I18n.t('footer.opening-hours')}</OpeningHours>
        <ContactList>
          <li>
            <StyledLink type="button" onClick={onChatClick}>
              {I18n.t('footer.by-chat')}
            </StyledLink>
          </li>
          <li>
            <StyledLink
              href={`tel:${I18n.t('footer.phone')}}`}
              onClick={() => {
                sendFooterNavigationEvent('par numéro');
              }}>
              {I18n.t('footer.phone')}
            </StyledLink>
          </li>
          <li>
            <StyledLink
              href={`mailto:${I18n.t('footer.email')}`}
              onClick={() => {
                sendFooterNavigationEvent('par email');
              }}>
              {I18n.t('footer.email')}
            </StyledLink>
          </li>
        </ContactList>

        <SocialIconsContainer>
          <StyledLink
            href={FACEBOOK_URL}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              sendFooterNavigationEvent('facebook');
            }}>
            <FacebookIcon />
          </StyledLink>
          <StyledLink
            href={INSTAGRAM_URL}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              sendFooterNavigationEvent('instagram');
            }}>
            <InstagramIcon />
          </StyledLink>
          <StyledLink
            href={LINKEDIN_URL}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              sendFooterNavigationEvent('linkedin');
            }}>
            <LinkedinIcon />
          </StyledLink>
        </SocialIconsContainer>
      </LinksInnerSection>
    </LinksSection>
  );
};

export default ContactUsSection;
